import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';

export default () => {

  const [keyword, setKeyword] = useState(null);
  const [response, setResponse] = useState(null);
  
  useEffect(
    () => {

      setKeyword( (new URLSearchParams(window.location.search)).get( 'keyword' ) ); 

      const getBlogs = (keyword) => {

        fetch(`https://MNNHVYV28P-dsn.algolia.net/1/indexes/Blogs/?query=${keyword}`,
          {
            method: 'GET',
            headers: new Headers({
              'X-Algolia-API-Key': `6edbf4a7f3f6dc2cb3d2732bfb384798`,
              'X-Algolia-Application-Id': `MNNHVYV28P`,
              'Content-Type': 'application/json'
            })
          })
          .then( response => response.json() )
          .then( response => setResponse(response) )
          .catch(error => console.log('error: ', error));
        }

        if (keyword !== null) getBlogs(keyword);

    }, [keyword]
  );

  // console.log('response state: ', response);

  const createHits = (response) => response.hits.map( (hit) => <li key={hit.objectID}><Link to={hit.handle}>{hit.blogTitle}</Link></li> );

  return (
    <Layout>
      <h1>Blogs Tagged: {keyword} </h1>
      <ul>
      { (response !== null && typeof response !== undefined) ? createHits(response) : <li>No matches</li> }
      </ul>
    </Layout>
  );
}

/*

TO DO: pull in the vars thru env vars - may need to do something with gatsby-node.js and edit some webpack setting ... DefinePlugin maybe?

fetch(`https://${process.env.GATSBY_ALGOLIA_APP_ID}-dsn.algolia.net/1/indexes/Blogs/?query=${keyword}`,

 headers: new Headers({
            'X-Algolia-API-Key': `${process.env.ALGOLIA_ADMIN_KEY}`,
            'X-Algolia-Application-Id': `${process.env.GATSBY_ALGOLIA_APP_ID}`,
            'Content-Type': 'application/json'
          })

*/